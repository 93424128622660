.App {
  text-align: center;
}

.App-header {
  background-color: #9c46fd;
  min-height: 100vh;
  display: flex;
   flex-direction: column;
  align-items: center;
  justify-content: center; 
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

h1{
  font-size: 70px;
  padding-left: 30px;
  padding-right: 30px;
  transition: font-size 0.5s;
}

h3{
  font-size: 40px;
}

@media(max-width:756px){
  h1{
    font-size: 50px;
    
  }
}




